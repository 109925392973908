import "./../App.css";
import {FormInputGroup} from "./../components/FormInputGroup.js";

export const LoginPage = ({state,handleChange,Login}) => {
  return (
    <section>
      <h1>Login</h1>
      <form onSubmit={(e) => {e.preventDefault(); Login(state.email,state.password)}}>
        <div className="inputGroup">
          <span>Email address</span>
          <FormInputGroup type="text" value={state.email} handleChange={handleChange} name="email" />
        </div>
        <div className="inputGroup">
          <span>Password</span>
          <FormInputGroup type="password" value={state.password} handleChange={handleChange} name="password" />
        </div>
        {state.showLoginError && <p className="error">{state.loginError}</p>}
        <button type="submit">Sign in</button>
      </form>
    </section>
  )
}

import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";
import { getAuth } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getStorage } from "firebase/storage";

// Original config
// const firebaseConfig = {
//   apiKey: "AIzaSyA1mlHhW7gdEzBHnkq8IlZMdC9m5KEe8Kc",
//   authDomain: "hologic-events.firebaseapp.com",
//   databaseURL: "https://hologic-events-default-rtdb.europe-west1.firebasedatabase.app",
//   projectId: "hologic-events",
//   storageBucket: "hologic-events.appspot.com",
//   messagingSenderId: "347751063524",
//   appId: "1:347751063524:web:cb0c74f20118895d8071f1",
//   measurementId: "G-942VW78BY0"
// };

// Trigger config
const firebaseConfig = {
  apiKey: "AIzaSyCcsV0CAiq5gZuHQcSiGIcLuQ4KSSsRbAU",
  authDomain: "hologic-events-trigger.firebaseapp.com",
  projectId: "hologic-events-trigger",
  storageBucket: "hologic-events-trigger.appspot.com",
  messagingSenderId: "750728507702",
  appId: "1:750728507702:web:aec612ee1b5f3b745c0397",
  measurementId: "G-ZGL5BL3SKQ"
};

const app = initializeApp(firebaseConfig);

// Create a ReCaptchaEnterpriseProvider instance using your reCAPTCHA Enterprise
// site key and pass it to initializeAppCheck().
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider("6Lf3jCYqAAAAAMP27IsMMsi9IfWBOUAgx6iONqWE"),
  isTokenAutoRefreshEnabled: true // Set to true to allow auto-refresh.
});

const functions = getFunctions(app, "europe-west1");
const db = getFirestore();
const storage = getStorage();
const auth = getAuth(app);

// Emulators
// connectFunctionsEmulator(functions, "localhost", 5001);
// connectFirestoreEmulator(db, 'localhost', 8080);

export {app, functions, db, storage, auth}
